<template>
  <Modal contact-modal>
    <div class="contact-form">
      <h3>H CORE <i>|</i> 문의하기</h3>
      <p class="sub">문의를 남겨주시면 담당자가 확인 후 메일을 보내드립니다.</p>
      <div class="form-list">
        <div class="list">
          <p>구분</p>
          <div class="select" :class="{on:isOn}">
            <a class="selected" @click="selectOpen">{{ param.category }}</a>
            <ul>
              <li @click="changeCategory('형강')">형강</li>
              <li @click="changeCategory('철근')">철근</li>
              <li @click="changeCategory('후판')">후판</li>
              <li @click="changeCategory('강관')">강관</li>
              <li @click="changeCategory('열연')">열연/냉연</li>
              <li @click="changeCategory('기타')">기타</li>
            </ul>
          </div>
        </div>
        <div class="list">
          <p>업체(상호)명</p>
          <input type="text" v-model="param.company">
        </div>
        <div class="list">
          <p> 담당자 <i>*</i></p>
          <input type="text" v-model="param.name">
        </div>
        <div class="list">
          <p>적용현장</p>
          <input type="text" v-model="param.site">
        </div>
        <div class="list">
          <p>이메일 <i>*</i></p>
          <input type="text" v-model="param.email">
          <span>*작성하신 이메일로 답변이 발송됩니다.</span>
        </div>
        <div class="list">
          <p>전화번호 <i>*</i></p>
          <input type="text" v-model="param.phone">
        </div>
        <div class="list">
          <p>문의내용 <i>*</i></p>
          <textarea v-model="param.cont"></textarea>
          <span>* 필수항목을 정확하게 입력해주세요.</span>
        </div>
        <div class="List">
          <input type="file" ref="fileInput" />
        </div>
        <div class="list agree">
          <p class="">
            (필수)개인정보수집 및 이용동의<br>
            <br>
            □ 개인정보의 수집 및 이용 동의 ㈜현대제철은 귀하께서 AE서비스포탈 홈페이지(https://ae.hyundai-steel.com/)의 이용약관의 내용에 대해 「동의한다」버튼 또는 「동의하지 않는다」버튼을 클릭할 수 있는 절차를 마련하여, 「동의한다」버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 간주합니다.<br/>
            <br/>
            ※ 본 서비스(사이트)는 만 14세 미만의 아동에 대한 개인정보를 수집하고 있지 않으며, 홈페이지에 아동에게 유해한 정보를 게시하거나 제공하고 있지 않습니다.<br/>
            <br/>
            AE서비스포탈 홈페이지는 Q&A 서비스 이용을 위해 필요한 최소한의 개인정보만을 수집합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행 할 예정입니다.<br/>
            <br/>
            Q&A, 귀하가 AE서비스포탈 홈페이지의 서비스를 이용하기 위해서 Q&A시 (국적, 이름, 이메일)를 필수적으로 입력하셔야 합니다. 그러나, 선택항목(회사)은 이를 입력하지 않더라도 서비스 이용에는 제한이 없습니다. 개인정보 항목별 구체적인 수집목적 및 이용목적은 다음과 같습니다.<br/>
            <br/>
            - 국적, 이름, 이메일 : 서비스 이용에 따른 본인 식별 절차에 이용 및 Q&A 답변시, 고지사항 전달, 불만처리 등을 위한 원활한 의사소통 경로의 확보 등에 활용<br/>
            - (선택)기타 선택항목 : 개인맞춤 서비스를 제공하기 위한 자료<br/>
            <br/>
            □ 수집하는 개인정보 항목<br/>

            ① 필수 개인정보 항목 : 국적, 이름, 이메일<br/>

            ② 선택 개인정보 항목 : 회사<br/>
            <br/>
            □ 개인정보의 보유기간 및 이용기간 AE서비스포탈 홈페이지내 수집된 개인정보의 보유기간은 다음과 같습니다.<br/>

            삭제요청시 즉시 파기되며, 1년간 추가 Q&A가 없을시 파기합니다.<br/>

            파기 시 AE서비스포탈 홈페이지는 회원님의 개인정보를 재생이 불가능한 방법으로 즉시 파기하며, 다만 다음 각호의 경우에는 각 호에 명시한 기간 동안 개인정보를 보유합니다.<br/>

            ① 상법 등 법령의 규정에 의하여 보존할 필요성이 있는 경우에는 법령에서 규정한 보존기간 동안 거래내역과 최소한의 기본정보를 보유함<br/>

            ② 보유기간을 회원님에게 미리 고지하고 그 보유기간이 경과하지 아니한 경우와 개별적으로 회원님의 동의를 받은 경우에는 약속한 보유기간동안 보유함<br/>
            <br/>
            □ 동의 거부 권리 및 동의 거부 시 불이익 내용 귀하는 개인정보의 수집목적 및 이용목적에 대한 동의를 거부할 수 있으며, 동의 거부시 AE서비스포탈 홈페이지에 Q&A서비스는 이용할 수 없습니다.<br/>
          </p>
        </div>
        <div class="list">
          <input ref="agreeVal" type="hidden" value="0" >
          <div class="check-box" id="yesBtn" @click="agreePolicy()"></div>
          <div class="check-box" id="noBtn" style="display: none" @click="agreePolicy()"><img src="/images/pc/checkbox_check.svg"></div>
          <p class="check-text" @click="agreePolicy()">개인 정보 제공 및 활용에 동의합니다.</p>
        </div>
      </div>
      <a class="submit" @click="submit">확인</a>
    </div>
    <a class="close" @click="$emit('resolve')">닫기</a>
  </Modal>
</template>

<script>
import Modal from "@/views/components/modal/Modal";
import axios from 'axios'
export default {
  name: "ContactModal",
  components: {Modal},
  props: ['options'],
  data() {
    return {
      isOn: false,
      param: {
        category: '',
        company: '',
        name: '',
        site: '',
        email: '',
        phone: '',
        cont: '',
      }
    }
  },
  methods: {
    selectOpen() {
      this.isOn =! this.isOn;
    },
    changeCategory(v) {
      this.param.category = v;
      this.isOn = false;
    },
    agreePolicy(){
      if(this.$refs.agreeVal.value == 1){
        this.$refs.agreeVal.value = 0;
        document.querySelector('#yesBtn').style.display = "";
        document.querySelector('#noBtn').style.display = "none";
      }else{
        this.$refs.agreeVal.value = 1;
        document.querySelector('#yesBtn').style.display = "none";
        document.querySelector('#noBtn').style.display = "";
      }

      console.log("agreeVal : "+this.$refs.agreeVal.value)
    },
    async submit() {

      if(this.$refs.agreeVal.value != 1){
        alert('개인 정보 제공 및 활용에 동의해 주십시요.');
        return;
      }

      if (!this.param.name) {
        alert('담당자를 입력해주세요');
        return;
      }

      if (!this.param.email) {
        alert('이메일을 입력해주세요');
        return;
      }

      if (!this.param.phone) {
        alert('전화번호를 입력해주세요');
        return;
      }

      if (!this.param.cont) {
        alert('문의내용을 입력해주세요');
        return;
      }

      const formData = new FormData();
      formData.append("category",this.param.category);
      formData.append("company",this.param.company);
      formData.append("name",this.param.name);
      formData.append("site",this.param.site);
      formData.append("email",this.param.email);
      formData.append("phone",this.param.phone);
      formData.append("cont",this.param.cont);
      formData.append("fileMail", this.$refs.fileInput.files[0]);

      await axios.post("/dist/sendMail", formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          //await axios.post("/dist/sendMail", formData)
          .then((response) => {
            alert("response : " + response.data.message);
            //alert(response.message);
            //alert("메일이 성공적으로 발송되었습니다 : " + response);
          }).catch((error) => {
            alert("에러가 발생하였습니다 : " + error);
            //alert("error : " + error);
            //alert(error.message);
          });
      /* 원본 소스
        await axios.post('/api/qna', this.param);
        this.$emit('resolve');
        alert('문의가 접수되었습니다.');

       */
    }
  },
  mounted() {
    this.param.category = this.options.category;
  }
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[contact-modal] {
  .contact-form { .w(93%); .p(60,80,55); .-box; .bgc(#fff); .-a(#ababab); .tc; .mh-c;
    h3 { .fs(36); .lh(40); .ls(-0.025em); .bold;
      i { .thin; .ib; .m(0,15); }
    }
    .sub { .fs(24); .lh(36); .ls(-0.025em); .mt(20); color:#999; }
    .form-list { .mt(36);
      .list { .tl; .mb(15);
        &:nth-child(1) p { .lh(50); }
        .check-box{ .wh(27,27) !important; .ib; .-a(#d1d5db); .fl; .mr(10); .pointer;}
        .check-text{ .underline; .pointer; .w(90%); .fl; }
        p { .fs(20); .lh(60); .w(20%); .ib; .vam;
          i { color:#ca5c5c; }
        }
        input, .select { .-a(@c-black); .h(60); .lh(60); .ib; .vam; .pl(20); .-box; }
        input { .fs(20); .w(80%); .spoqa; }
        .select { .wh(200,48); .lh(48); .rel; .pl(0);
          &:after { .cnt; .wh(26,13); .contain('/images/mo/ico-select-arrow.png'); .abs; .rt(16,18); pointer-events: none; }
          .selected { .fs(24); .pointer; z-index: 1; .ib; .f; .pl(20); .-box; }
          ul { .wh(200,0); .abs; .lt(-2,46); .-box; .-t; .bgc(#fff); .crop; }
          &.on {
            &:after { transform: rotate(180deg); }
            ul { height:auto; .-a(@c-black);
              li { .fs(24); .-a; .f; .lh(58); .vat; background: transparent; .pl(20); .-box;
              }
            }
          }
        }
        span { .fs(20); .pl(20%); .-box; .mt(5); .ib; color:#0079ae; }
        textarea { .fs(20); .wh(100%,120); .-a(@c-black); .-box; .p(20); .spoqa; }
        textarea + span { .pl(0); color:#ca5c5c; }
        &.agree { .p(15); .-box; .-a(@c-black); .h(80); .scroll;
          p { .wf; .lh(32); }
        }
      }
    }
    .submit { .fs(24); .tc; .wh(250,60); .lh(60); .bgc(#0d1112); .ib; color:#fff; .mt(10); }
  }
}

@media screen and(min-width:1024px) {
  [contact-modal] {
    .contact-form { .w(550); .p(50,60,45); .-box; .wf;
      h3 { .fs(30); .lh(36); }
      .sub { .fs(18); .lh(36); .mt(12); }
      .form-list { .mt(45);
        .list {
          p { .fs(18); .lh(40); .w(125); }
          input, .select { .h(40); .lh(40); }
          input { .fs(18); .w(300); }
          .select { .wh(200,50); .lh(50);
            .selected { .fs(18); }
            &.on {
              ul { .lt(-1,48);
                li { .fs(18); .lh(50); .pointer;
                  &:hover { .bgc(#efefef); }
                }
              }
            }
          }
          span { .fs(16); .pl(125); }
          textarea { .fs(18); .h(100); }
          &.agree { .h(60);
            p { .fs(14); .lh(20); }
          }
        }
      }
      .submit { .fs(16); .wh(250,40); .lh(40); }
    }
  }
}
</style>